import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Context } from "@context/store.jsx";
import { useAxiosPrivate } from "@api/axios.jsx";

export default function LocationPicker() {
  const [filteredLocations, setFilteredLocations] = useState({});
  const [allProperties, setAllProperties] = useState([]);
  const [{ bookingInfo, allPropertiesAvailability }, dispatch] =
    useContext(Context);
  const getAllProperties = async () => {
    await fetch(
      process.env.NEXT_PUBLIC_SERVER_BASE_URL +
        `/api/guest/property/getAllProperty`,
      {
        next: { revalidate: 3600 },
      }
    )
      .then((res) => res.json())
      .then((res) => setAllProperties(res.data));
  };
  useEffect(() => {
    getAllProperties();
  }, []);

  const availableProperties =
    allPropertiesAvailability.length > 0
      ? allPropertiesAvailability
      : allProperties;
  const selectedLocation = bookingInfo.location;
  const axios = useAxiosPrivate();
  const handleSelectLocation = (location, city) => {
    dispatch({
      type: "bookingInfo",
      payload: {
        ...bookingInfo,
        location: {
          location,
          city,
        },
      },
    });
  };

  useEffect(() => {
    if (!availableProperties || availableProperties.length === 0) return;

    const locationCount = {};

    availableProperties.forEach(({ property }) => {
      const { area, city } = property;
      if (!locationCount[area]) {
        locationCount[area] = { neighborhoods: {}, total: 0 };
      }
      if (!locationCount[area].neighborhoods[city]) {
        locationCount[area].neighborhoods[city] = 0;
      }
      locationCount[area].neighborhoods[city] += 1;
      locationCount[area].total += 1;
    });

    setFilteredLocations(locationCount);
  }, [availableProperties]);

  return (
    <>
      <li onClick={() => handleSelectLocation("all", "")}>
        <div>
          <img src="/assets/icons/svgs/pinDropHome.svg" />
          <h5>
            All <span>({availableProperties?.length})</span>
            {selectedLocation?.location === "all" && (
              <FontAwesomeIcon icon={faCheck} className="check-mark" />
            )}
          </h5>
        </div>
      </li>
      {Object.keys(filteredLocations).map((location, index) => (
        <li key={index} onClick={() => handleSelectLocation(location, "")}>
          <div>
            <img src="/assets/icons/svgs/pinDropHome.svg" />
            <h5>
              {location} <span>({filteredLocations[location].total})</span>
              {selectedLocation?.location === location && (
                <FontAwesomeIcon icon={faCheck} className="check-mark" />
              )}
            </h5>
          </div>
          <ul>
            {Object.keys(filteredLocations[location].neighborhoods).map(
              (city, cityIndex) => (
                <li
                  key={cityIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectLocation(location, city);
                  }}
                >
                  <div>
                    <img src="/assets/icons/svgs/pinDropHome.svg" />
                    <h5>
                      {city}
                      <span>
                        ({filteredLocations[location].neighborhoods[city]})
                      </span>
                      {selectedLocation?.city === city && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="check-mark"
                        />
                      )}
                    </h5>
                  </div>
                </li>
              )
            )}
          </ul>
        </li>
      ))}
    </>
  );
}

