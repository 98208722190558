"use client";
import { useRouter } from "next/navigation";
import React, { useContext } from "react";
import HotelIcon from "@icons/svgs/hotel.svg";
import ApartmentIcon from "@icons/svgs/apartment.svg";
import CheckMark from "@icons/svgs/checkMark.svg";
import { Context } from "@context/store";

const PropertyTypeSelector = () => {
  const router = useRouter();
  const [{ selectedType: currentType }, dispatch] = useContext(Context);
  const handleClick = (type) => {
    dispatch({ type: "selectedType", payload: type });
    router.push(`/?type=${type}`, {
      scroll: false,
    });
  };

  return (
    <>
      <div className="property-types">
        <button
          className={`type ${currentType === "" ? "selected" : ""}`}
          onClick={() => handleClick("")}
        >
          {currentType === "" ? <CheckMark /> : <HotelIcon />}
          All
        </button>
        <button
          className={`type ${currentType === "hotel" ? "selected" : ""}`}
          onClick={() => handleClick("hotel")}
        >
          {currentType === "hotel" ? <CheckMark /> : <HotelIcon />}
          Hotels
        </button>
        <button
          className={`type ${currentType === "apartment" ? "selected" : ""}`}
          onClick={() => handleClick("apartment")}
        >
          {currentType === "apartment" ? <CheckMark /> : <ApartmentIcon />}
          Apartments
        </button>
      </div>
    </>
  );
};

export default PropertyTypeSelector;
