"use client";
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  faAngleDown,
  faAngleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DurationDatePicker from "../../DurationDatePicker";
import GuestsAmountPicker from "../../GuestsAmountPicker";
import LocationPicker from "../../LocationPicker";
import { usePathname, useRouter } from "next/navigation";
import AmenitiesPicker from "../../AmenitiesPicker";
import dayjs from "dayjs";
import Link from "next/link";
import { Context } from "@context/store";
import { useAxiosPrivate } from "@api/axios";

const SearchSection = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showGuestsPicker, setShowGuestsPicker] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [showAmenitiesPicker, setShowAmenitiesPicker] = useState(false);
  const locationPickerRef = useRef(null);
  const datePickerRef = useRef(null);
  const guestPickerRef = useRef(null);
  const amenitiesRef = useRef(null);
  const [state, dispatch] = useContext(Context);
  const axios = useAxiosPrivate();
  const {
    location,
    start_date,
    amenities: selectedAmenities,
  } = state.bookingInfo;

  const amenities = state.allPropertyAmenities;
  const propertyType = state.selectedType;
  const { adults, children, pets, infants } = state.bookingInfo.guests;

  const pathname = usePathname();
  const handleSearch = () => {
    const filters = selectedAmenities;
    async function fetchProperties() {
      dispatch({
        type: "loader",
        payload: true,
      });
      await axios
        .get("/api/guest/property/getAllProperty", {
          params: { ...state.bookingInfo, filters, propertyType },
        })
        .then((response) => {
          dispatch({
            type: "allPropertiesAvailability",
            payload: response.data.data,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => dispatch({ type: "loader", payload: false }));
    }
    fetchProperties();
  };

  const handleClickOutside = (event) => {
    if (
      guestPickerRef.current &&
      !guestPickerRef.current.contains(event.target)
    ) {
      setShowGuestsPicker(false);
    }
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
    if (
      locationPickerRef.current &&
      !locationPickerRef.current.contains(event.target)
    ) {
      setShowLocationPicker(false);
    }
    if (amenitiesRef.current && !amenitiesRef.current.contains(event.target)) {
      setShowAmenitiesPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 350) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <div
      className={`container ${isScrolled ? "collapsed" : ""} search-section`}
    >
      <div className="search-bar">
        <div className="search-item">
          <label>Location</label>
          <div ref={locationPickerRef} className="drop-down">
            <button
              className="show-date-picker"
              onClick={() => setShowLocationPicker((prev) => !prev)}
            >
              {location?.city || location?.location || "select Location"}
              <FontAwesomeIcon
                className="round-svg"
                icon={showLocationPicker ? faAngleUp : faAngleDown}
              />
            </button>
            <ul
              className={`collapse-location location-picker ${
                showLocationPicker ? "" : "hidden"
              }`}
            >
              <LocationPicker />
            </ul>
          </div>
        </div>
        <div className="search-item">
          <label>Date</label>
          <div ref={datePickerRef} className="drop-down">
            <button
              className="show-date-picker"
              onClick={() => setShowDatePicker((prev) => !prev)}
            >
              {dayjs(start_date).format("DD/MM/YYYY") || "select date"}
              <FontAwesomeIcon
                className="round-svg"
                icon={showDatePicker ? faAngleUp : faAngleDown}
              />
            </button>
            <div className={`collapse-date ${showDatePicker ? "" : "hidden"}`}>
              <DurationDatePicker />
            </div>
          </div>
        </div>
        <div className="search-item">
          <label>Guests</label>
          <div ref={guestPickerRef} className="drop-down">
            <button
              className="show-date-picker"
              onClick={() => setShowGuestsPicker((prev) => !prev)}
            >
              {adults + children + infants + pets || "select"} Guests
              <FontAwesomeIcon
                className="round-svg"
                icon={showGuestsPicker ? faAngleUp : faAngleDown}
              />
            </button>
            <div
              className={`collapse-guest ${showGuestsPicker ? "" : "hidden"}`}
            >
              <GuestsAmountPicker />
            </div>
          </div>
        </div>
        <div className="search-item item-amenities-picker">
          <label>Filters</label>
          <div ref={amenitiesRef} className="drop-down">
            <button
              className="show-date-picker show-amenities-picker"
              onClick={() => {
                setShowAmenitiesPicker((prev) => !prev);
              }}
            >
              <>
                {selectedAmenities.length === 0 ? (
                  <h6>All</h6>
                ) : selectedAmenities.includes("all") ? (
                  <h6>All</h6>
                ) : (
                  <>
                    <h5>
                      {
                        amenities.find(
                          (item) => item._id === selectedAmenities[0]
                        )?.name
                      }
                    </h5>
                    {selectedAmenities.length > 1 ? (
                      <h4>+{selectedAmenities.length - 1}</h4>
                    ) : null}
                  </>
                )}
              </>
              <FontAwesomeIcon
                className="round-svg"
                icon={showAmenitiesPicker ? faAngleUp : faAngleDown}
              />
            </button>
            <ul
              className={`collapse-location amenities-picker ${
                showAmenitiesPicker ? "" : "hidden"
              }`}
            >
              <AmenitiesPicker />
            </ul>
          </div>
        </div>
        <div className="search-item">
          {pathname == "/" ? (
            <Link href={"/AllProperties"} className="submit-button">
              <FontAwesomeIcon icon={faSearch} />
            </Link>
          ) : (
            <button className="submit-button" onClick={() => handleSearch()}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
