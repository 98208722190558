"use client";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PinDropHomeIcon from "@icons/svgs/pinDropHome.svg";
import PinDropIcon from "@icons/svgs/pinDrop.svg";
import LeftArrow from "@icons/svgs/leftArrow.svg";
import DateIcon from "@icons/svgs/date.svg";
import GuestsIcon from "@icons/svgs/guests.svg";
import LocationPicker from "@components/LocationPicker";
// const DurationDatePicker = lazy(() => import(`@components/DurationDatePicker`));
const DurationDatePicker = lazy(() => import(`@components/DurationDatePicker`));
import HotelIcon from "@icons/svgs/hotel.svg";
import ApartmentIcon from "@icons/svgs/apartment.svg";
import CheckMark from "@icons/svgs/checkMark.svg";

import dayjs from "dayjs";
import GuestsAmountPicker from "@components/GuestsAmountPicker";
import AmenitiesPicker from "@components/AmenitiesPicker";
import { Context } from "@context/store";
import { useAxiosPrivate } from "@api/axios";
import { useRouter } from "next/navigation";
import PropertyTypeSelector from "../../PropertyTypeSelector";
const MobileNav = () => {
  const [{ selectedType, bookingInfo }, dispatch] = useContext(Context);
  const {
    amenities: selectedAmenities,
    start_date,
    end_date,
    location,
  } = bookingInfo;
  const { adults, children, pets, infants } = bookingInfo.guests;
  const [isClient, setIsClient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const axios = useAxiosPrivate();
  //Location Picker
  const [showLocationPicker, setShowLocationPicker] = useState(false);

  //Date Picker
  const [showDatePicker, setShowDatePicker] = useState(false);

  //Guests Picker
  const [showGuestsPicker, setShowGuestsPicker] = useState(false);

  //amenities Picker
  const [showAmenitiesPicker, setShowAmenitiesPicker] = useState(false);

  const [showPropertyTypeSelect, setShowPropertyTypeSelector] = useState(false);
  //Refs
  const locationPickerRef = useRef(null);
  const datePickerRef = useRef(null);
  const guestPickerRef = useRef(null);
  const amenitiesRef = useRef(null);
  const propertyTypeRef = useRef(null);
  const router = useRouter();
  const handleSearch = async () => {
    if (router.pathname == "/") return router.push("/AllProperties");
    const filters = selectedAmenities.map((amenity) => amenity._id);
    await axios
      .get("/api/guest/property/getAllProperty", {
        params: { ...bookingInfo, filters },
      })
      .then((response) => {
        dispatch({
          type: "allPropertiesAvailability",
          payload: response.data.data,
        });
      });
    setShowModal(false);
  };

  const handleClickOutside = (event) => {
    if (
      guestPickerRef.current &&
      !guestPickerRef.current.contains(event.target)
    ) {
      setShowGuestsPicker(false);
    }
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
    if (
      locationPickerRef.current &&
      !locationPickerRef.current.contains(event.target)
    ) {
      setShowLocationPicker(false);
    }
    if (amenitiesRef.current && !amenitiesRef.current.contains(event.target)) {
      setShowAmenitiesPicker(false);
    }
    if (
      propertyTypeRef.current &&
      !propertyTypeRef.current.contains(event.target)
    ) {
      setShowPropertyTypeSelector(false);
    }
  };

  useEffect(() => {
    setIsClient(true);
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    isClient && (
      <>
        <div className="container sticky mobileNav ">
          <div className="mobile-search" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <div className="mobile-search-item gray">
              <PinDropIcon />
              <p>
                {location.location
                  ? location.location == "all"
                    ? "All Locations"
                    : location.location
                  : "Location"}
              </p>
            </div>
            <div className="mobile-search-item">
              <p>
                {" "}
                {start_date
                  ? dayjs(start_date).format("MMM YYYY") +
                    " - " +
                    dayjs(end_date).format("MMM YYYY")
                  : "Choose a date"}
              </p>
            </div>
          </div>
        </div>
        <AnimatePresence>
          <motion.div
            initial={{ translateY: "100%" }}
            animate={{ translateY: showModal ? 0 : "100%" }}
            exit={{ translateY: "100%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className={`mobile-search-modal`}
          >
            <LeftArrow
              className="back-button"
              onClick={() => setShowModal(false)}
            />
            <div className="modal-search-item" ref={locationPickerRef}>
              <div
                className="item-title"
                onClick={() => setShowLocationPicker((prev) => !prev)}
              >
                <PinDropHomeIcon />{" "}
                <p>
                  {location.location
                    ? (location.location == "all"
                        ? "All Location"
                        : location.location) +
                      (location.city ? ", " + location.city : "")
                    : "Location"}
                </p>
              </div>
              <motion.div
                initial={{ height: "0px" }}
                animate={{
                  height: showLocationPicker ? "300px" : "0px",
                }}
                exit={{
                  height: "0px",
                  transition: { duration: 0.3, ease: "linear" },
                }}
                className="modal-search-item-body"
              >
                <ul>
                  <LocationPicker />
                </ul>
              </motion.div>
            </div>
            <div className="modal-search-item" ref={datePickerRef}>
              <div
                className="item-title"
                onClick={() => setShowDatePicker((prev) => !prev)}
              >
                <DateIcon />{" "}
                <p>
                  {start_date
                    ? dayjs(start_date).format("MMM YYYY") +
                      " - " +
                      dayjs(end_date).format("MMM YYYY")
                    : "Choose a date"}
                </p>
              </div>
              <motion.div
                initial={{ height: "0px" }}
                animate={{
                  height: showDatePicker ? "auto" : "0px",
                }}
                exit={{
                  height: "0px",
                  transition: { duration: 0.3, ease: "linear" },
                }}
                className="modal-search-item-body"
              >
                <div
                  initial={{ height: "0px" }}
                  animate={{
                    height: "auto",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  exit={{
                    height: "0px",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  className="modal-search-item-body"
                >
                  <Suspense>
                    <DurationDatePicker />
                  </Suspense>
                </div>
              </motion.div>
            </div>
            <div className="modal-search-item" ref={guestPickerRef}>
              <div
                className="item-title"
                onClick={() => setShowGuestsPicker((prev) => !prev)}
              >
                <GuestsIcon />
                <p>{adults + children + infants + pets || "select"} Guests</p>
              </div>
              <motion.div
                initial={{ height: "0px" }}
                animate={{
                  height: showGuestsPicker ? "auto" : "0px",
                }}
                exit={{
                  height: "0px",
                  transition: { duration: 0.3, ease: "linear" },
                }}
                className="modal-search-item-body"
              >
                <div
                  initial={{ height: "0px" }}
                  animate={{
                    height: "auto",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  exit={{
                    height: "0px",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  className="modal-search-item-body"
                >
                  <GuestsAmountPicker />
                </div>
              </motion.div>
            </div>
            <div className="modal-search-item" ref={amenitiesRef}>
              <div
                className="item-title"
                onClick={() => setShowAmenitiesPicker((prev) => !prev)}
              >
                <GuestsIcon />
                <div className="amenities-preview">
                  {selectedAmenities && selectedAmenities.includes("all") ? (
                    "All"
                  ) : selectedAmenities?.length !== 0 ? (
                    selectedAmenities.length > 2 ? (
                      <>
                        {selectedAmenities.slice(0, 2).map((item) => (
                          <h5 key={item.id || item.name}>{item.name}</h5>
                        ))}
                        <h4>+{selectedAmenities.length - 2}</h4>
                      </>
                    ) : (
                      Array.isArray(selectedAmenities) &&
                      selectedAmenities.map((item) => (
                        <h5 key={item.id || item.name}>{item.name}</h5>
                      ))
                    )
                  ) : (
                    "Filters"
                  )}
                </div>
              </div>
              <motion.div
                initial={{ height: "0px" }}
                animate={{
                  height: showAmenitiesPicker ? "300px" : "0px",
                }}
                exit={{
                  height: "0px",
                  transition: { duration: 0.3, ease: "linear" },
                }}
                className="modal-search-item-body"
              >
                <motion.ul
                  initial={{ height: "0px" }}
                  animate={{
                    height: "300px",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  exit={{
                    height: "0px",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  className="modal-search-item-body"
                >
                  <div className="amenities-picker">
                    <AmenitiesPicker />
                  </div>
                </motion.ul>
              </motion.div>
            </div>
            <div className="modal-search-item" ref={propertyTypeRef}>
              <div
                className="item-title"
                onClick={() => setShowPropertyTypeSelector((prev) => !prev)}
              >
                {selectedType == "apartment" ? (
                  <ApartmentIcon />
                ) : (
                  <HotelIcon />
                )}
                <p className="hotel-type">{selectedType || "All"}</p>
              </div>
              <motion.div
                initial={{ height: "0px" }}
                animate={{
                  height: showPropertyTypeSelect ? "200px" : "0px",
                }}
                exit={{
                  height: "0px",
                  transition: { duration: 0.3, ease: "linear" },
                }}
                className="modal-search-item-body"
              >
                <motion.ul
                  initial={{ height: "0px" }}
                  animate={{
                    height: "100%",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  exit={{
                    height: "0px",
                    transition: { duration: 0.3, ease: "linear" },
                  }}
                  className="modal-search-item-body"
                >
                  <>
                    <h4 className="title">What are you looking for?</h4>
                    <div className="property-types-mobile">
                      {/* <button
                        className={`type-mobile ${
                          selectedType === "" ? "selected" : ""
                        }`}
                        onClick={() =>
                          dispatch({ type: "selectedType", payload: "" })
                        }
                      >
                        {selectedType === "" ? <CheckMark /> : <HotelIcon />}
                        All
                      </button>
                      <button
                        className={`type-mobile ${
                          selectedType === "hotel" ? "selected" : ""
                        }`}
                        onClick={() =>
                          dispatch({ type: "selectedType", payload: "hotel" })
                        }
                      >
                        {selectedType === "hotel" ? (
                          <CheckMark />
                        ) : (
                          <HotelIcon />
                        )}
                        Hotel
                      </button>
                      <button
                        className={`type-mobile ${
                          selectedType === "apartment" ? "selected" : ""
                        }`}
                        onClick={() =>
                          dispatch({
                            type: "selectedType",
                            payload: "apartment",
                          })
                        }
                      >
                        {selectedType === "apartment" ? (
                          <CheckMark />
                        ) : (
                          <ApartmentIcon />
                        )}
                        Apartment
                      </button> */}
                      <PropertyTypeSelector />
                    </div>
                  </>
                </motion.ul>
              </motion.div>
            </div>
            <div className="modal-footer">
              <div className="button-container">
                <div
                  className="button-link"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </div>
                <button
                  className="submit-button"
                  onClick={() => handleSearch()}
                >
                  Next
                </button>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </>
    )
  );
};

export default MobileNav;
