"use client";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "@context/store.jsx";
import PropertyTypeSelector from "../PropertyTypeSelector";
import { useAxiosPrivate } from "@api/axios.jsx";

export default function AmenitiesPicker() {
  const [amenitiesToShow, setAmenitiesToShow] = useState({});
  const [{ bookingInfo }, dispatch] = useContext(Context);
  const selectedAmenities = bookingInfo.amenities;
  const [allPropertyAmenities, setAllPropertyAmenities] = useState([]);
  const axios = useAxiosPrivate();
  const getPropertyAmenities = async () => {
    await axios
      .get("/api/guest/amenities/getAllPropertyAmenities")
      .then((response) => {
        setAllPropertyAmenities(response.data.data);
        dispatch({
          type: "allPropertyAmenities",
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPropertyAmenities();
  }, []);
  useEffect(() => {
    const groupByCategory = (items) => {
      if (!Array.isArray(items)) return {};
      return items.reduce((acc, item) => {
        const { category } = item;
        if (!category) return acc;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {});
    };

    const groupedAmenities = groupByCategory(allPropertyAmenities);
    setAmenitiesToShow(groupedAmenities);
  }, [allPropertyAmenities]);

  useEffect(() => {
    if (selectedAmenities.length === 0) {
      // If no amenities are selected, default to "all"
      dispatch({
        type: "bookingInfo",
        payload: { ...bookingInfo, amenities: ["all"] },
      });
    }
  }, [selectedAmenities, dispatch, bookingInfo]);

  const handleToggleAmenity = (toggledAmenityId) => {
    if (toggledAmenityId === "all") {
      // Select "all" and clear other selected amenities
      dispatch({
        type: "bookingInfo",
        payload: { ...bookingInfo, amenities: ["all"] },
      });
    } else {
      if (selectedAmenities.includes("all")) {
        // If "all" is selected, remove it when another amenity is chosen
        dispatch({
          type: "bookingInfo",
          payload: {
            ...bookingInfo,
            amenities: [toggledAmenityId],
          },
        });
      } else if (selectedAmenities.includes(toggledAmenityId)) {
        // Deselect the amenity if it's already selected
        const newSelectedAmenities = selectedAmenities.filter(
          (amenityId) => amenityId !== toggledAmenityId
        );

        // If no amenities are left, default back to "all"
        dispatch({
          type: "bookingInfo",
          payload: {
            ...bookingInfo,
            amenities:
              newSelectedAmenities.length > 0 ? newSelectedAmenities : ["all"],
          },
        });
      } else {
        // Add the selected amenity
        dispatch({
          type: "bookingInfo",
          payload: {
            ...bookingInfo,
            amenities: [...selectedAmenities, toggledAmenityId],
          },
        });
      }
    }
  };

  return (
    <>
      <li className="hide-on-mobile">
        <PropertyTypeSelector />
      </li>
      <li className="all-select" onClick={() => handleToggleAmenity("all")}>
        <h5>
          <input
            type="checkbox"
            checked={selectedAmenities.includes("all")}
            readOnly
          />
          All
        </h5>
      </li>
      {Object.keys(amenitiesToShow).map((category) => (
        <div className="category" key={category}>
          <h4>{category}</h4>
          <ul>
            {amenitiesToShow[category].map((amenity) => (
              <li
                key={amenity._id}
                onClick={() => handleToggleAmenity(amenity._id)}
              >
                <h5>
                  <input
                    type="checkbox"
                    checked={selectedAmenities.includes(amenity._id)}
                    readOnly
                  />
                  {amenity.name}
                </h5>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}
